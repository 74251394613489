<template>
  <div class="qr-code-container">
    <BrandButton
      v-if="!qrData"
      @click="getQRCode"
      shape="rounded"
      class="qr-code-button"
      :radius="50"
    >
      <VueText :isSingleLine="true" color="white-100" sizeLevel="4" weightLevel="2">
        QR kod
      </VueText>
    </BrandButton>
    <img v-else :src="qrData" alt="qr" width="100px" height="100px" />
  </div>
</template>

<script>
import { ref } from 'vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import { User } from '@/services/Api/index';

export default {
  name: 'QRCode',
  components: {
    VueText,
    BrandButton,
  },
  setup() {
    const qrData = ref('');

    const getQRCode = async () => {
      try {
        const res = await User.getQRCode();
        qrData.value = `data:image/jpeg;base64,${res.data.Data.base64QrCode}`;
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    };

    return {
      qrData,
      getQRCode,
    };
  },
};
</script>

<style scoped lang="scss">
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .qr-code-button {
    width: 100px !important;
    height: 30px !important;
  }
}
</style>
